$black-border: 3px solid #333333;
$font: 'Open Sans', Helvetica, Arial, sans-serif;
$blue-accent: #007bff;
$blue-accent-hover: darken(#007bff, 10%);
$primary-accent: #be3e82;
$background-color: #f0f4f8;

$new-UI-off-black: #333333;
$new-UI-navy: #0c69a2;
$new-UI-primary-hover: #be3e82;
$new-UI-primary-text: #e43f6f;
$new-UI-primary-text-dark: #be3e82;
$new-UI-primary-text-light: #fde8f3;
$new-UI-primary-text-light-hover: #b1a5a7;
$new-UI-navy-selected: #2d384a;
$new-UI-mid-grey: #888888;
$new-UI-light-grey: #eeeeee;
$new-UI-warning-bg: #ffb62f;
$new-UI-warning-text: #3e2d22;
$new-UI-light-blue: #a6b9c7;

$macaroni-and-cheese: #f0b429;

$disabled-text: #777777;
$new-UI-navy-light-text: #4b5a6b;
$new-UI-light-blue-text: #627d98;
$grey-title: #565656;
$new-UI-dark-text: #2d384b;

$card-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
$selected-shadow: 0 0 0 2px $new-UI-navy-selected;
$highlight-shadow: 0 2px 12px 0 rgba(10, 102, 157, 0.45);
