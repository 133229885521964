.react-numeric-input {
  input[type=text]:not(.browser-default) {
    margin: 0;
    height: 36px;
    max-width: 100%;
    &:focus {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
  b {
    background: none !important;
    border: none !important;
    &:hover {
      background: #eee !important;
      cursor: pointer !important;
    }
  }
}

.ReactModal__Content {
  .close-modal-cross {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
}