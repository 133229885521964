.bs-popover-right::after {
  position: absolute;
  left: -10px;
  top: 47%;
  content: '';
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  border-left: 1px solid #0004;
  border-bottom: 1px solid #0004;
}
