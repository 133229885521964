#event-summary {

  .list-title {
    padding: 0.8em 0 0.2rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.04rem;
    color: #000;
  }

  .list-item {

    border-bottom: 1px solid #000;
    padding: 0.4em 0;

    .list-caption {
      position: relative;
      display: inline-block;
      font-size: 1rem;
      font-weight: 400;
      color: #000;
      clear: both;
    }
    .list-price {
      float: right;
      font-size: 1rem;
      font-weight: 400;
    }

  }

  .info {
    font-size: 1rem;
    line-height: 28px;
    font-weight: 400;
  }

  h1 {
    &.summary-header {
      border-bottom: 2px solid #555;
      padding: 0.75rem 0;
    }
  }

  h2, h3 {
    border-bottom: 2px solid #bbb;
    padding: 10px 0;
  }

  h4 {
    border-bottom: 2px solid #eee;
    padding: 10px 0;
  }

  .final-total {
    padding: 0 2rem;
    border-bottom: 2px solid #bbb;
    h2 {
      font-weight: 600;
      border: none;
      padding: 0;
    }
    &.catering-total {
      h2 {
        font-weight: 400;
      }
    }
  }

  .breakdown-title {
    margin: 1rem 0 -0.5rem;
    padding-left: 1rem;
  }

  .list-subtotal-cost h3 {
    border-bottom: none;
    border-top: 2px solid #bbb;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .row.marketing-preference {
    padding: 0.75em 0;
    background: #f7f7f7;
    margin: 0 0 1em;
    font-size: 0.9em;
  }
}