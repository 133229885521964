@import '../variables';

body {
  -webkit-print-color-adjust: exact !important;
}

:root {
  --next-button-colour: #ccc;
  --stripe-width: 2px;
  --stripe-width-double: calc(var(--stripe-width) + var(--stripe-width));

  --striped-background: repeating-linear-gradient(
    -45deg,
    var(--blocked-colour),
    var(--blocked-colour) var(--stripe-width),
    #fff var(--stripe-width),
    #fff var(--stripe-width-double)
  );

  --blocked-colour: #f2f2f2;
  --booked-colour: var(--striped-background);
  --booked-text-colour: #cfcfcf;
  --provisional-colour: #ffb51a;
  --provisional-text-colour: #24538;
  --past-date-background-colour: #eeeeee;
  --past-date-text-colour: #777;
  --past-date-opacity: 0.3;
  --selected-date-colour: #637d97;
  --selected-date-text-colour: #eff3f8;
  --closed-colour: #1e3755;
  --closed-text-colour: #ffffff;
  --corona-text-colour: #ffffff;
  --corona-colour: #0aa741;
  --default-date-colour: #dfecfb;
  --current-date-colour: #be3e82;
  --current-date-text-colour: #eff3f8;
  --default-date-text-colour: #245382;
  --month-header-colour: #fff;
  --legend-display: none;
  --calendar-font-size: 14px;
  --calendar-background-colour: #ffffff;
  --error-colour: #cf4946;
}

#cordero-availability-calendar {
  font-family: $font;
  font-size: var(--calendar-font-size);
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  margin: 50px auto;
  padding: 10px;
  position: relative;

  .color-key {
    display: flex;
    list-style: none;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
    margin-left: 30px;

  //  li {
  //      position: relative;
  //    padding-left:30px;
  //
  //      //&::before {
  //      //    content: '';
  //      //    position: absolute;
  //      //    width: 25px;
  //      //    height: 25px;
  //      //    top: 50%;
  //      //    left: -30px;
  //      //    transform: translateY(-50%);
  //      //}
  //  }
    //
    //:nth-child(1)::before {
    //    background-color: var(--default-date-colour);
    //}
    //:nth-child(2)::before {
    //    background: var(--striped-background);
    //}
    //:nth-child(3)::before {
    //    background-color: var(--provisional-colour);
    //}
    //:nth-child(4)::before {
    //    background-color: var(--closed-colour);
    //}
  }

  .skip-to-dropdown {
    span {
      float: left;
      margin: 5px 10px 0 10px;
    }
    select {
      display: block;
      width: 20%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: var(--default-date-text-colour);
      border: 1px solid #eeeeee;
    }
  }

  .navigation button {
    border:none;
    color:#be3e82;
    background:none;
  }

  .navigation button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .calendar {
    flex-wrap: wrap;
    justify-content: flex-start;
    display: flex;
  }

  .loading-container {
    color: var(--default-date-text-colour);
    margin: 32% auto 32% auto;
    width: 100%;
    text-align: center;
  }
  .load-more-link {
    text-align: center;
    width: 100%;
    color: var(--default-date-text-colour);
  }
  @media (max-device-width: 560px) {
    .month, .calendarCol {
      width: 100%;
    }
  }
  @media (min-device-width: 560px) and (max-device-width: 860px) {
    .month,  .calendarCol {
      width: 48%;
    }

  }
  @media (min-device-width: 860px) {
    .month, .calendarCol {
      width: 32%;
    }
  }
  .calendarCol {
    padding:5px;
  }

  .month {
    margin: 40px 0.4rem;
    flex: 0 0 100%;
    width:100%;
  }
  // .calendarCol {
  //   width:32%;
  //   padding:5px;
  // }

  .week {
    width: 100%;
    display: flex;

    &:not(.names) .day {
      cursor: pointer;
    }
  }

  .month-header {
    width: 100%;
    background: var(--month-header-colour);
    height: 30px;
    font-weight: bold;
    color: var(--default-date-text-colour);
    text-align: center;
  }

  span.day {
    text-align: center;
    height: 40px;
    line-height: 40px !important;
    font-weight: 600;
    background: var(--default-date-colour);
    color: var(--default-date-text-colour);
    flex: 1;
    margin: 1px;

    &.closed,
    &.booked,
    &.provisional,
    &.previous-month,
    &.today,
    &.past {
      pointer-events: none;
      cursor: default !important;
      font-weight: 300;
    }
    &.current {
      background: var(--current-date-colour);
      color: var(--current-date-text-colour);
    }
    &.booked {
      background: var(--booked-colour);
      color: var(--booked-text-colour);
    }
    &.provisional {
      background: var(--provisional-colour);
      color: var(--provisional-text-colour);
    }
    &.closed {
      background: var(--closed-colour);
      color: var(--closed-text-colour);
    }
    &.past {
      background: var(--past-date-background-colour);
      color: var(--past-date-text-colour);
      opacity: var(--past-date-opacity);
      border-left-color: #717171 !important;
    }
    &.today {
      border:1px solid  var(--default-date-text-colour);
      background: var(--default-date-colour);
      color: var(--default-date-text-colour);
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      opacity: 1;
    }

    &.previous-month {
      background: rgb(255, 255, 255);
      color: rgb(255, 255, 255);
    }
  }
  .names > span {
    color: #525252;
    /* font-weight: 500; */
    line-height: 1.6rem !important;
    height: 1.6rem !important;
    font-size: 0.8rem;
    background: #f7f7f7;
    font-weight: 300;
  }

  .poweredBy {
    position: absolute;
    right: 10px;
    border-radius: 15px;
    top: -15px;
    background: #ffffff;
    border: 2px solid $primary-accent;
    padding: 5px 10px 5px 10px;

    a {
      color: var(--default-date-text-colour);
      text-decoration: none;
    }
  }
}
.cordero-modal-open {
  place-items: center;
  display:grid;
  background-color: transparent !important;
}
.cordero-modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 3.5rem);
}
.cordero-modal {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  color: var(--default-date-text-colour);
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  height: 100%;

  .close {
    float: right;
    background: none;
    border: none;
    font-size: 2rem;
  }

  &.modal-open {
    overflow: hidden;
  }

  .modal-dialog {
    height:100vh;
    display:grid;
    place-items: center;

    border-radius: 0.5rem;
  }

  .modal-header {
    border: none !important;
  }
  .modal-content {
    width:760px;
    position: relative;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
  }
  .left-column  {
    width:49%;
    float:left;
  }
  .right-column {
    padding-left:10px;
    width:49%;
    float:right;
  }
  .modal-body {
    position: relative;
    width: 100%;
    font-family: $font;
    overflow: hidden;
    border: none !important;

    .price-container {
      font-family: $font;
      text-align: center;

      .price-text {
        font-size: 1.5rem;
        margin: 0 0 10px 0;
        letter-spacing: normal;
      }
      .price {
        font-size: 2rem;
        margin-bottom: 20px;
        letter-spacing: normal;
      }
    }
    h5 {
      text-align: center;
      margin-top: 10px;
      font-weight: 300;
      font-size:17px;
    }
    .submit-btn-container {
      clear:both;
      display:block;
      height:100px;
    }
    .quote-btn {
      text-align:center;
    }
    .submit-btn {
      cursor: pointer;
      background: #0B69A3;
      color: #fff;
      padding: 20px 30px;
      border-radius:5px;
      margin-bottom:20px;
      margin-top:20px;
      border:none;
      font-size:14px;
      text-transform: uppercase;
    }
    select,
    input {
      display: block;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.6;
      border-radius: 0.25rem;
      border: 1px solid #ced4da;
      width: 100%;
      letter-spacing: normal;
      padding: 0.5rem;
      margin: 15px 0 !important;
      box-sizing: border-box;
    }
    .form-container {
      margin-top: 20px;
    }

    .form-inputs {
      display: block;
    }

    .guest-row {
      display: flex;
      .guest-col {
        width: 32%;
        padding-right: 15px;
      }
    }

    .guest-numbers-title {
      font-size: 1.2rem;
      margin: 20px 0;
    }
  }
}
.availability_clear {
  clear:both;
}
.legendKey {
  //position:fixed;
  height:20px;
  width:20px;
  //background:red;

  display:inline-block;
  float:left;
}
.legendKeyLabel {
  float:left;
  margin-left:10px;
}