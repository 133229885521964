#quote #final-summary {
  [class^="col-"].summary-next-steps {
    //border: 2px solid $navy-accent;
    margin: 1rem 0 3rem;
    padding: 0 2rem 2rem;
    background: #ffffff;
    box-shadow: $card-shadow;
    h1 {
      margin-bottom: 1rem;
      color: $primary-accent;
    }
    p {
      margin-bottom: 1.5rem;
    }
  }

  .panel {
    box-shadow: $card-shadow;
    background: #ffffff;
    padding: 0;
  }

  .event-details-widget {
    margin-top: 2rem;
  }

  .step-summary {
    padding: 1.3rem 2rem 1.4rem;
    //border: 2px solid #666;
    box-shadow: $card-shadow;
    background: #ffffff;
    box-sizing: border-box;
    margin-top: 1rem;
    & + .step-summary {
      margin-top: 2rem;
    }
  }

  button.empty-menu-add {
    padding: 1rem 0.5rem;
    margin: -0.75rem 0 1rem;
    border: 1px solid #00377d;
    color: #00377d;
    line-height: 1.8rem;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }

  .list-title {
    padding: 0.8em 0 0.2rem;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.04rem;
    color: #000;
  }

  .list-item {

    border-bottom: 1px solid #000;
    padding: 0.4em 0;

    .list-caption {
      position: relative;
      display: inline-block;
      font-size: 1rem;
      font-weight: 400;
      color: #000;
      clear: both;
    }
    .list-price {
      float: right;
      font-size: 1rem;
      font-weight: 400;
    }

  }

  .list-subtotal-cost {
    border-top: 1px solid;
    margin: 0.5rem 0 0;
    padding-top: 0.5rem;
  }

  .step-title-row {
    /* border-bottom: 1px solid #bbb; */
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: $new-UI-primary-text;
    & + .header-row {
      margin-top: -0.5rem;
    }
  }

  .breakdown-title {
    padding: 2rem 0 0.5rem;
  }

  .product-row {
    font-size: 1rem;
    font-weight: 300;
  }

  .header-row {
    border-bottom: 1px solid #ddd;
    margin: 1.25rem 0;
    & + & {
      margin-top: 0;
    }
    .step-title-row + & {
      margin-top: -0.5rem;
    }

    div[class^="col"] {
      padding: 0;
    }
    .name {
      font-size: 1.25rem;
      font-weight: 400;
      color: $new-UI-primary-text;
    }
  }

  .product-group-row {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    .header-row + & {
      margin-bottom: 0;
    }
    .name {
      font-size: 1rem;
      font-weight: 300;
      font-style: italic;
      color: $new-UI-navy-light-text;
    }
  }

  .name {
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  .venue-hire-summary {
    margin: 0.5rem 0 1.5rem;
  }

  .final-total {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: $new-UI-primary-text-dark;
    &.catering-total {
      padding: 1rem 0 0.5rem;
      border-bottom: 2px solid #333;
    }
  }

}