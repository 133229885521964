// *** Guest Widget *** //

#guests-widget {
  .add-new-placeholder {
    line-height: 1.5em;
    margin-bottom: 6px;
  }
}

.guest-row {
  & + .guest-row {
    margin-top: 0.5em;
  }
  .Select {
    margin-right:-15px;
  }
  .react-quantity-wrapper {
    margin:0 -15px 0 0;
    .react-numeric-input {
      margin-right: 0;
      max-width: 100%;
      input {
        border-radius: 5px;
      }
    }
  }
  .react-numeric-input {
    margin-right: -15px;
    margin-left: 0 !important;
  }
  .guest-row-delete {
    line-height: 36px;
    float: right;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
}

.guest-total {
  .react-numeric-input {
    input {
      border: none !important;
      border-bottom: 1px solid #EEEEEE !important;
    }
  }
}

.guest-total-label {
  text-align: right;
  line-height: 36px;
  padding-right: 0;
}

.create-button {
  transition:  opacity 0.5s;
  opacity: 1;
  &.fade-out {
    transition:  opacity 2s;
    opacity: 0;
    pointer-events: none;
  }
  &.success {
    background: green;
  }
}

.add-new-placeholder {
  text-align: center;
  line-height: 2em;
  border: 1px solid #cccccc;
  margin-top: 14px;
  font-size: 1.5em;
  color: #777777;
  width: 100%;
  display: block;
  &:hover {
    background: #f3f3f3;
    color: #1a456d;
    cursor: pointer;
  }
}
