@import 'variables';

@import 'components/guests';
@import 'components/react-module-overrides';
@import 'components/popover';
@import 'availability/style.scss';
@import 'tools/animations';

@import 'pages/event-summary';
@import 'pages/final-summary';

.btn {
  border-radius: 4px;
}

.btn-primary,
.btn-primary:disabled,
.btn-primary.disabled,
.btn-primary:hover {
  background-color: $primary-accent;
  color: #fff !important;
  border: none;
}

.btn-primary:disabled,
.btn-primary.disabled,
.btn-primary.inactive {
  background-color: $new-UI-mid-grey;
  //opacity: inherit;
  opacity: 0.65;
  border: none;
  color: #fff !important;
}

.btn-outline-primary,
.btn-outline-primary:hover {
  background: none;
  color: $primary-accent !important;
  border: 1px solid $primary-accent;
  border-radius: 4px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: inherit;
  background-color: $new-UI-primary-text-dark;
  border-color: inherit;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}

html {
  min-height: 100vh;
  background: $background-color;
}

.color-ui-navy {
  color: $new-UI-primary-text;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #fefefe;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem + 2vmin);
  color: #1a456d;
  min-height: 6rem;
  border-bottom:4px solid #e43f6f;
  img {
    max-height:100px;
    max-width:300px;
    padding:10px;
  }
}

a {
  color: $new-UI-primary-text;
  &:hover {
    color: $new-UI-primary-text-dark;
  }
}

.badge {
  font-weight: 600;
}

.badge-hollow {
  box-shadow: 0 0 0 1px $new-UI-mid-grey;
  color: $new-UI-mid-grey;
}

.badge-secondary {
  background-color: $new-UI-primary-text-dark;
}

.error-marker {
  position: absolute;
  background: $new-UI-warning-bg;
  width: fit-content;
  max-width: 100%;
  padding: 0 1em;
  line-height: 22px;
  text-align: center;
  border-radius: 5px;
  color: $new-UI-warning-text;
  font-size: 12px;
  font-weight: 600;
  z-index: 9999;
  &::before {
    content: '';
    height: 0;
    width: 0;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
  }
  $error-offset: calc(100% + 0.75em);
  &.arrow-right {
    right: 100%;
    top: calc(50% - 1em);
    &::before {
      border-left: 7px solid $new-UI-warning-bg;
      top: calc(50% - 5px);
      right: -1em;
    }
  }
  &.arrow-left {
    left: 100%;
    top: calc(50% - 1em);
    &::before {
      border-right: 7px solid $new-UI-warning-bg;
      top: calc(50% - 5px);
      left: -1em;
    }
  }
  &.arrow-top {
    top: $error-offset;
    right: 0;
    padding: 0.5em 1em;
    margin: 0 1rem;
    &::before {
      border-bottom: 7px solid $new-UI-warning-bg;
      top: -1em;
      left: calc(50% - 5px);
    }
  }
  &.arrow-bottom {
    bottom: $error-offset;
    right: 0;
    padding: 0.5em 1em;
    &::before {
      border-top: 7px solid $new-UI-warning-bg;
      bottom: -1em;
      left: calc(50% - 5px);
    }
  }
  &.fade {
    animation: fade_error 2500ms 0s ease;
    opacity: 0;
  }
  &.fade-slow {
    animation: fade_error 5000ms 0s ease;
    opacity: 0;
  }

  .error-list {
    padding: 0.5rem 1.5rem;
    text-align: left;
    .error-list-item {
      &:before {
        content: '\B7';
        width: 1em;
        height: 1.5rem;
        position: absolute;
        left: 0;
        font-size: 1.5rem;
        line-height: 1.2rem;
      }
    }
  }
}

.tooltip.tooltip-navy {
  .tooltip-inner {
    background-color: $new-UI-primary-text-dark;
  }
  &.bs-tooltip-top .arrow::before {
    border-top-color: $new-UI-primary-text-dark;
  }
  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: $new-UI-primary-text-dark;
  }
  &.bs-tooltip-left .arrow::before {
    border-left-color: $new-UI-primary-text-dark;
  }
  &.bs-tooltip-right .arrow::before {
    border-right-color: $new-UI-primary-text-dark;
  }
}

.light-shadow {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.small-link {
  font-size: 0.75rem;
  color: $new-UI-primary-text !important;
  text-transform: uppercase;
  margin-bottom: -1.25rem;
  display: block;
  padding-top: 0.5rem;
  &:hover {
    cursor: pointer;
  }
}

.step-container:first-of-type {
  border-bottom: 1px solid $new-UI-light-grey;
  margin-bottom: 1rem;
}

.step-line:hover {
  cursor: pointer;
}

.step-number-circle {
  border-radius: 50%;
  border: 1px solid $new-UI-primary-text;
  padding: 0.5em 0.5em;
  height: calc(2em + 2px);
  color: $new-UI-primary-text;
  font-size: 20px;
}

.step-number-icon {
  height: 1em;
  width: 1em;
  line-height: 1em;
  text-align: center;
}

.step-title {
  font-weight: 500;
  font-size: 16px;
  color: $new-UI-primary-text;
  padding-left: 0;
}

.step-description {
  font-size: 11px;
}

.step-info {
  color: $new-UI-mid-grey;
  font-size: 14px;
  margin-right: 1em;
}

.sidebar-collapse {
  width: 100%;
  background: #fff;
  text-align: center;
  line-height: 3rem;

  &:hover {
    cursor: pointer;
  }
}

.Sidebar {
  background: #ffffff;
  margin: 2rem 0 1rem;
  //padding: 1rem;
  -webkit-box-sizing: $card-shadow;
  -moz-box-sizing: $card-shadow;
  box-shadow: $card-shadow;
  font-size: 0.9rem;
  position: sticky;
  max-height: calc(100vh - 74px);
  z-index: 202;
  //overflow-y: scroll;
  //overflow-x: hidden;

  .sidebar-header {
    font-size: 1.4rem;
    padding: 1rem 1rem;
    margin-bottom: 0;
    line-height: 1.5rem;
  }

  .sidebar-header-container {
    -webkit-box-sizing: $card-shadow;
    -moz-box-sizing: $card-shadow;
    box-shadow: $card-shadow;
  }

  .sidebar-header-details {
    font-weight: 500;
    color: $new-UI-off-black;
    line-height: 1.5rem;
  }
  .sidebar-title {
    background: $background-color;
    margin-left: -5px;
    font-size: 1rem;
    font-weight: 300;
    & + .product-group-sidebar {
      margin-top: 0.5rem;
    }
  }

  .final-summary-btn-container {
    padding: 15px;
  }
  .final-summary-btn {
    padding: 0.5rem;
    font-size: 1rem;
  }

  .sidebar-content {
    overflow-y: scroll;
    max-height: calc(100vh - 12rem);
    padding: 0 1rem 1rem;
    position: relative;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $new-UI-light-blue;
      border-radius: 5px;
      height: 25px;
    }
  }

  .sidebar-footer {
    background: #fff;
    box-shadow: $card-shadow;
    position: sticky;
    bottom: 0;
  }

  .grand-total {
    //margin-top: 1.5rem;
    padding: 1rem;

    h4 {
      font-size: 1.25rem;
      margin: 0;
    }
  }

  &.event-details-widget {
    overflow-y: unset;
    max-height: fit-content;
  }

  .collapse-summary {
    margin-right: -14px;
  }
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 250;
}

.SidebarPage {
  //padding: 0 0 0.5rem;
  padding-bottom: 0.2rem;
  .row {
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }

  .product-group-sidebar {

    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    position: relative;
    color: $grey-title;
    //font-style: italic;
    font-weight: 300;
    //margin-bottom: 0.25rem;
    &.product-subgroup-sidebar {
      margin-top: -0.2rem;
      //margin-bottom: 0.25rem;
      font-style: italic;
    }
  }

  .product-sidebar {
    .sidebar-product-price {
      color: $grey-title;
    }
    .empty-sidebar-option {
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      font-style: italic;
      margin-top: 0.2rem;
      //margin-bottom: 0.2rem;
    }
  }
}

.event-details-widget {
  color: $new-UI-navy-light-text;
  z-index: 210;
  padding: 1rem;
  //margin-top: -3.1rem;

  a:hover {
    cursor: pointer;
  }
}

.location-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  //margin: -1rem -1rem 1rem;
  padding: 3rem;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .overlay {
    z-index: 5;
  }

  .filter {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 3;
    background: rgba(0, 7, 0, 0.5);
  }

  p {
    margin-bottom: 1.8rem;
  }

  .summary-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
  }

  .btn-contact {
    font-size: 14px;
    line-height: 1.7;
  }
}

.event-details-container {
  padding-right: 0;
  padding-left: 25px;
}

.event-detail-text {
  //width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding-top: 0.6rem;
  display: inline-block;
  & + .event-detail-text {
    padding-left: 1rem;
  }
}

.event-detail-edit {
  color: $new-UI-primary-text-dark;
  &:hover {
    cursor: pointer;
  }
}

.event-details-header {
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  line-height: 1.5rem;
}

.quote-info-text {
  font-size: 0.75rem;
  color: $new-UI-navy;
  font-style: italic;
  padding-top: 1rem;
  i {
    font-size: 1.5rem;
    margin-right: 0.8rem;
  }
}

.quote-intro-row {
  background: #ffffff;
  box-shadow: $card-shadow;
  border-bottom: 4px solid #e43f6f;
}

.event-details-col {
  padding-left: 20px;
  padding-right: 0;
}

.location-image-col {
  padding-left: 25px;
  padding-right: 0;
}

.Page {
  .sub-page {
    padding: 1rem 0;
    & > .collapse,
    & > .collapsing {
      margin: 0 -15px;
      padding: 0 15px;
      max-width: 'stretch';
      .ProductGroup {
        .product-group-toggle-row:first-of-type {
          margin-top: 1rem;
        }
      }
    }
    .section-title {
      font-size: 1.25rem;
    }
  }
  .collapse-section {
    width: 100%;
  }
}

.GroupPage {
  width: 100%;

  .sub-page {
    width: 100%;
  }

  &:not(:last-child) .sub-page {
    border-bottom: 1px solid #dddddd;
  }
}

.subgroup-title {
  margin: 3rem 0 0;
  padding-top: 0;
  color: $new-UI-dark-text;
  font-weight: 700;
  font-size: 18px;
  display: inline-block;
}

.product-group-description {
  margin-top: 0.25rem;
  color: $new-UI-navy-light-text;
  font-size: 13px;
  font-style: italic;
}

.ProductGroup {
  justify-content: space-between;
  width: 100%;

  &:first-of-type .subgroup-title {
    margin-top: 1.5rem;
  }
  .product-group-toggle-row {
    padding: 0.5rem 0;
  }
  & + .ProductGroup {
    margin-top: 0;
    .subgroup-title {
      padding-top: 4rem;
      margin: 0;
    }
  }

  .ProductGroup:first-of-type {
    .product-group-toggle-row {
      margin-top: 0;
    }
  }

  .ProductGroup {
    .subgroup-title {
      margin: 1.5rem 0 0;
      padding-top: 0;
    }
    .row.product-group-toggle-row {
      margin-top: 0;
      padding: 0.8rem 0;
      margin-bottom: -1rem;
    }

    & + .ProductGroup .product-group-toggle-row {
      margin-top: 1rem;
    }

    .subgroup-title {
      font-size: 1.1rem;
      font-weight: 400;
      color: inherit;
    }
  }

  .collapse-products {
    .Product:last-child .product-item {
      border: none;
    }
  }

  & + .ProductGroup {
    .product-group-toggle-row {
      margin-top: 3.5rem;
    }
  }

  & + .Product {
    margin-top: 1rem;
  }
}

.ProductGroup + .continue-button-row,
.Product + .continue-button-row,
.a-la-carte-items + .continue-button-row {
  margin-top: 1rem;
}

.filter-popup-modal.one-column {
  .Product.product-item {
    &.col-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.Product.product-item {
  margin: 0 0 15px 0;
  -webkit-user-select: none;
  //padding: 1rem 1.5rem;
  box-shadow: $card-shadow;
  background: #ffffff;
  border: none;
  min-height: 96px;
  padding: 0;

  &.col-md-6 {
    flex: 0 0 calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }

  .badges-container {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: auto;
  }

  .corner-badge {
    background-color: $new-UI-primary-text-dark;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    .fa-stack {
      font-size: 12px;
      width: 26px;
      line-height: 26px;
    }
    & + .corner-badge {
      margin-top: 4px;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.selected {
    //box-shadow: ($card-shadow, $selected-shadow);
    background: $new-UI-navy-selected;
    &.card .card-body,
    &.card .card-footer {
      background: $new-UI-navy-selected !important;
      color: #ffffff;
    }
    .product-title {
      color: #ffffff;
    }

    .selected-badge {
      color: $new-UI-primary-text-dark;
      background: none;
      font-size: 22px;
      line-height: 1;
    }
    .caption .quantity-text {
      color: #ffffff;
    }
    .product-description {
      color: $new-UI-light-grey;
    }
  }

  &.highlight {
    box-shadow: $highlight-shadow;
    .filtered-badge {
      background-color: #86c2ea;
      padding: 1px 4px;
      line-height: 20px;
    }
    &.selected {
      box-shadow: ($selected-shadow, $highlight-shadow);
    }
  }

  &.compulsory {
    background: #f9f9f9;




    &:hover {
      cursor: default;
    }

    .compulsory-badge {
      background: $new-UI-light-blue;
      padding: 0 5px;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      &.selected-badge {
        color: #fff;
        background: $new-UI-primary-text-dark;
      }
    }

  }

  &.disabled {
    //pointer-events: none;
    background: #f9f9f9;
    color: $disabled-text;

    .product-title {
      color: $disabled-text;
    }

    &:hover {
      cursor: default;
    }
  }



  .product-title {
    margin: 0;
    text-align: left;
    font-size: 1rem;
    color: $new-UI-off-black;
    padding: 0 1rem 0.25rem 0;
    font-weight: 500;
    max-width: 100%;
    -webkit-user-select: text;
  }

  .product-more-info {
    display: inline;
    margin: 0 0.5rem;
    color: $new-UI-primary-text;
    font-size: 1rem;
  }

  .product-description {
    font-size: 14px;
    font-style: italic;
    color: $new-UI-navy-light-text;
    //margin: 0.25rem 0;
  }

  .caption {
    display: flex;
    font-size: 0.9rem;
    //text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.5px;
    font-weight: 300;
    //padding: .4rem 0 0;
    position: relative;
    width: 100%;
  }

  .price-caption-container {
    line-height: 19px;
  }

  .quantity-caption-container {
    position: absolute;
    bottom: 1.1em;
  }

  .quantity-text {
    font-weight: 600;
    //color: $new-UI-primary-text-light;
    display: inline;

    .quantity-text-inner {
      margin-right: 2px;
      display: inline;
    }
  }

  .price-text {
    //padding: 0.5rem 0;
    display: inline;
  }
}
.price-text {
  position:absolute;
  bottom:20px;
  right:20px;
}
.filter-group {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 1rem;
  padding: 0 0 0.5rem;
  .ProductGroup .subgroup-title {
    margin: 1rem 0 0.5rem;
  }
  .collapse-products {
    padding-bottom: 1rem;
  }

  a.open-filter-group {
    color: $new-UI-primary-text;
    font-size: 14px;
    font-weight: 500;
    padding-top: 1rem;
    display: block;
    &:hover {
      color: $new-UI-primary-text-dark;
      cursor: pointer;
    }
  }
}

.product-modal-title {
  margin-bottom: 1rem;
  color: $new-UI-primary-text-dark;
}

.Link,
.ModalLinkHeader {
  font-size: 1.25rem;
  padding: 0 0 0.5rem;
  color: $new-UI-navy;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    color: #999 !important;
    pointer-events: none;

    :hover {
      cursor: default;
    }
  }
}

.NonLinkHeader {
  font-size: 1.25rem;
  padding: 0 0 0.5rem;
}

.Quote {
  .collapse:not(.show) {
    display: inherit;
    height: 0;
    overflow: hidden;
  }

  .collapse,
  .collapsing {
    transition: height 1s ease-in-out;
    width: 100%;
  }
  .a-la-carte-items {
    &.collapse,
    &.collapsing {
      margin: 0 -15px;
      padding: 0 15px;
      width: auto;
    }
    & > .subgroup-title {
      //border-top: 1px solid #dddddd;
      margin-top: 1rem;
      //padding: 1rem 0 0;
      width: 100%;
    }
    .ProductGroup:first-of-type .subgroup-title {
      margin: 1rem 0 0;
    }
  }
  .header-container {
    box-shadow: 0 2px 12px #0001;
  }

  .App-header > .header-logo {
    height: 3.5vh;
    min-height: 1.6rem;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 12px 0;
  }

  .quote-heading {
    margin-left: -15px;
    margin-bottom: 2rem;
  }

  .quote-intro-container {
    //background: #fff;
    position: relative;
    //border-bottom: 1px solid #ddd;
    z-index: 201;
    display: flex;
    color: $grey-title;
    margin-top: 2rem;
    .container {
      margin: auto;
    }
    h2 {
      color: $new-UI-dark-text;
    }
  }
  .quote-intro-description {
    font-size: 14px;
    padding-top: 0.75rem;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $new-UI-primary-text-dark;
    }
    ul {
      list-style: decimal;
      padding-left: 1.5rem;
    }
    li + li {
      margin-top: 0.5rem;
    }
  }
  .quote-info-long-description {
    border-top: 1px solid $new-UI-light-grey;
    margin-top: 1rem;
    &:not(.collapsing):not(.show) {
      padding-top: 0;
    }
  }

  .logo-bar-container {
    background: #fff;
    position: relative;
    border-bottom: 1px solid #ddd;
    z-index: 201;
  }

  .sidebar-container {
    z-index: 110;
  }

  .top-nav-container {
    //background: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 400;
    box-shadow: $card-shadow;
    flex: 1 1;
    margin-top: 1.5rem;
    padding: 0;
    & > .row {
      background: #f0f4f8;
      margin: -15px -15px 0;
      padding: 15px 15px 0;
    }
  }

  .alert {
    margin: 1rem 0 0;
  }

  .Menu > .Page {
    margin-top: 1rem;
  }
}

.event-details-modal {
  font-family: 'Open Sans', sans-serif;
  color:#212529;
  select {
    padding:10px;
    width:100%;
  }

  h5 {
    margin-top:20px;
  }
  #cordero-availability-calendar {
    margin-bottom:20px;
  }
  .guest-row {
    display: flex;
    margin-bottom:20px;
    .guest-col {
      width: 32%;
      padding-right: 15px;
    }
  }
  button {
    float:right;
    padding:10px 20px;
  }
}

.Availability {
  font-size: 15px;

  input:read-only {
    background: none;
  }
}

.QuantityPicker {
  display: block;
  margin-right: 5px;
  margin-bottom: -0.5rem;

  .product-quantity {
    max-width: 2.5rem;
    background-color: transparent;
    color: $new-UI-primary-text-dark;
    border: none !important;
    font-weight: 700;
    font-size: 18px !important;
    line-height: 20px !important;
  }
  .item-btn {

    color: $new-UI-primary-text-dark;
    border: 2px solid $new-UI-primary-text-dark;
    border-radius:50%;
    width:24px;
    height:24px;
    padding:0px;
    text-align: center;
    &.disabled {
      border: 2px solid #333333;
      color: #333333;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

// a.item-btn:not([tabindex]) {
//   font-size: 1.5rem;
//   color: $new-UI-primary-text-light;
//   vertical-align: sub;

//   &:hover:not(.icon-disabled) {
//     cursor: pointer;
//     color: $new-UI-primary-text-light-hover;
//   }

//   &.disabled {
//     color: #333333;
//     opacity: 0.5;
//     pointer-events: none;
//   }
// }

.card {
  .card-body {
    padding: 1rem 1rem 0;
    background: #fff;
    border-radius: 0.25rem 0.25rem 0 0;
    flex: 0;
  }

  .card-footer {
    background: #fff;
    border-radius: 0 0 0.25rem 0.25rem;
    border: none;
    min-height: 16px;
    //padding: 0 1rem 0.5rem;
    padding: 0 1rem 0.8rem;
    flex: 1 1;
  }
}

#cordero-quote {
  background: #f0f4f8;
  font-family: 'Open Sans', sans-serif;

  .Main .Availability {
    background: #fff;
    padding: 2rem;
    box-shadow: $card-shadow;
  }

  h1 {
    margin-top: 2rem;
    margin-bottom: 0.25rem;
  }

  a.call-to-action-split {
    flex: 1;
    line-height: 2.5rem;
    border: 1px solid $primary-accent;
    //color: $primary-accent;
    text-align: center;
    color: $primary-accent;
    margin: 1rem 0.5rem 0;

    &.auto {
      flex: auto;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .continue-button {
    background: none;
    color: #4a90e2 !important;
    box-shadow: none;
    padding: 5px;
    text-transform: uppercase;

    &.btn.disabled {
      color: #999 !important;
      pointer-events: inherit;
    }

    i {
      font-size: 1.1em !important;
    }

    .btn.disabled {
      background-color: #ffffff;
    }
  }

  .card-deck .card-deck {
    margin-right: inherit;
    margin-left: inherit;
    justify-content: space-between;
  }

  .bottom-black-border {
    //border-bottom: $black-border;
    padding: 1rem 0;
    //margin-bottom: -0.5rem;
    .products-list > &:first-child,
    .products-list > .sub-group:first-child > &:first-child {
      border-top: $black-border;
      margin-top: -3px;
    }

    .continue-button {
      padding: 0;
    }
  }

  .page-title {
    margin: 0;
  }

  .section-row {
    border-bottom: 1px solid $new-UI-mid-grey;
    &:hover {
      cursor: pointer;
    }
  }

  .product-group-nav {
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    justify-content: space-between;

    .nav-item {
      flex: 0 0 auto;
      -ms-flex: 1 0 auto;
      text-align: left;
      &:last-of-type {
        margin-right: 1rem;
      }

      .nav-link.active:hover {
        color: #fff !important;
      }

      &.show {
        .nav-link {
          background-color: inherit;
        }
        .dropdown-menu {
          margin: 0;
          padding: 0;
          .dropdown-item {
            padding: 0.6rem 1rem;
            font-size: 14px;
            font-weight: 600;
            color: $new-UI-primary-text;
            &:hover {
              color: $new-UI-primary-text-dark;
              cursor: pointer;
            }
            &:active {
              background-color: inherit;
            }
          }
        }
      }
    }

    .nav-link {
      color: $new-UI-primary-text;
      //padding: 0.75rem 1.5rem 0.75rem 0;
      line-height: 1.8em;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        color: $new-UI-primary-text-dark;
        cursor: pointer;
      }

      &.dropdown-toggle {
        padding-right: 0;
      }
    }
  }
}

.modal-dialog {
  margin-top: auto !important;
  margin-bottom: auto !important;
  width: 100%;
}

.ReactModal__Overlay {
  z-index: 2000;
}

.menu-modal,
.react-modal {
  height: auto;
  display: block;
  //width: 40%;
  margin: auto;
  //top: 15vh;
  position: absolute;

  &.show {
    display: flex !important;
  }

  .close-modal {
    right: 1rem;
    top: 1rem;
    line-height: 1rem;
    width: 1rem;
    color: #7f7f7f;
    text-align: center;
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }

  .modal-text {
    padding: 2.5rem 2.5rem;

    p,
    ul {
      margin-bottom: 0;
    }

    label {
      color: $new-UI-primary-text-dark;
    }
  }

  .modal-title {
    margin-top: -0.25rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
  }

  .modal-content {
    border: none;
  }

  .modal-footer {
    display: flex;
    padding: 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    //border-top: 2px solid #333;
  }

  h2 {
    font-weight: 300;
  }

  button {
    flex: 1;
    line-height: 3rem;
    font-size: 1.1rem;
    border: none;
    letter-spacing: 1px;
    margin-top: 1.5rem;

    &.disabled {
      background: #eeeeee;
      color: #555555;
    }
  }

  button[class^='modal-btn-'] {
    &:not(.disabled):hover {
      cursor: pointer;
    }
  }
}

.filter-popup-modal {
  .filter-popup-list-page {
    color: $new-UI-primary-text-dark;
    border-bottom: 1px solid $new-UI-primary-text-dark;
    padding-bottom: 0.25rem;
    & + .filter-popup-list-product {
      margin-top: 0.5rem;
    }
    &:first-child {
      margin-top: 0 !important;
    }
  }
  .filter-popup-list-group {
    & + .filter-popup-list-group {
      margin-top: 0.25rem !important;
      font-style: italic;
      h5 {
        font-size: 1rem;
      }
    }
  }
  .page-description {
    margin-top: 0.25rem;
    color: $new-UI-navy-light-text;
    font-size: 13px;
    font-style: italic;
  }
  .modal-dialog {
    top: 7vh;
    margin-bottom: 10vh !important;
    .modal-content {
      background: #f5f5f5;
      padding: 2rem;
    }
  }
  .card {
    .card-body {
      padding: 1rem 1rem 0;
      background: #fff;
      border-radius: 0.25rem 0.25rem 0 0;
      flex: 0;
    }

    .card-footer {
      background: #fff;
      border-radius: 0 0 0.25rem 0.25rem;
      border: none;
      min-height: 16px;
      //padding: 0 1rem 0.5rem;
      padding: 0 1rem 1rem;
      flex: 1 1;
    }
  }
  .ProductGroup .subgroup-title {
    margin: 1rem 0 0;
  }
  .ProductGroup {
    .ProductGroup {
      .group-title {
        margin: 1rem 0 0;
        font-size: 1.4rem;
      }
      .subgroup-title {
        margin: 0.5rem 0 0;
      }
    }
    & + .ProductGroup {
      .group-title {
        padding-top: 2rem;
        margin-bottom: 0;
      }
      .subgroup-title {
        padding-top: 0;
      }
    }
  }
  .subtotal-row {
    margin-top: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
    div {
      flex: 1 1 auto;
    }
  }
}

.modal-btn-group {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: -1rem;
  position: relative;
  .btn {
    width: 100%;
    padding: 0.5rem;
  }
  .btn-wrapper {
    flex: 0 1 calc(50% - 0.5rem);
  }
}

@media only screen and (max-width: 792px) {
  .menu-modal {
    width: 80%;
    margin: auto;
    top: 20vh;
    position: absolute;

    .modal-text {
      padding: 2rem 2rem 4rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .filter-popup-modal {
    h2 {
      font-size: 1.4rem;
    }
    .close-modal {
      top: 0.7rem;
    }
  }
  .filter-popup-list {
    margin-right: 15px;
    margin-left: 15px;
  }
  .event-details-modal {
    .modal-content {
      padding: 0.5rem;
      .close-modal {
        right: 0.8rem;
        top: 0.5rem;
      }
    }
  }
  #quote .Quote {
    margin-bottom: 5rem;
    .App-header > .header-logo {
      margin: 7px 0;
    }
    .header-container {
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 200;
      .mobile-header-summary-icon {
        font-size: 20px;
        color: $new-UI-navy;
        //color: $macaroni-and-cheese;
      }
    }
    .quote-intro-container {
      display: none;
    }

    .quote-info-long-description {
      padding-right: 1rem;
      padding-left: 1rem;
    }

    .small-link {
      margin-bottom: -0.9rem;
    }

    .quote-info-text {
      padding: 0.25rem 1rem 0;
    }

    .event-details-container {
      border-bottom: 1px solid $new-UI-light-grey;
      margin-top: 11px;
      padding-right: inherit;
      padding-left: inherit;
    }

    .event-details-inner {
      padding: 1rem;
    }

    .event-detail-text {
      font-size: 13px;
    }

    .event-details-widget {
      //margin: 0 -15px;
      z-index: 110;
      padding: 0 0 1rem;
    }
    .location-image {
      //height: 11rem !important;
      p {
        font-weight: 600;
      }
      .summary-title {
        margin-top: 3rem;
      }
    }
    #final-summary .event-details-widget {
      margin-top: -2px;
      margin-right: -15px;
      margin-left: -15px;
    }
    .top-nav-container.mobile-nav {
      border-bottom: 1px solid $new-UI-light-grey;
      box-shadow: none;
      z-index: 300;
      max-width: 100vw;
      overflow-x: scroll;
      transform: translateZ(0);
      width: auto;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      //&::before {
      //  content: '';
      //  height:100%;
      //  width: 100%;
      //  box-shadow: inset -16px 0px 12px #fff;
      //  position: fixed;
      //  top: 0;
      //  right: 0;
      //}

      .mob-nav-gradient-scroll {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 500;
        .mob-nav-gradient {
          content: '';
          width: 3rem;
          background: linear-gradient(to right, #ffffff00, #ffffffff);
          right: 1rem;
        }
        .mob-nav-solid {
          content: '';
          background: #ffffff;
          width: 1rem;
          right: 0;
        }
      }
    }
    .product-group-nav .nav-link {
      padding: 0.5rem 1.5rem 0.6rem 0;
    }

    .filter-group {
      padding: 0;
      .ProductGroup .subgroup-title {
        margin: 0.5rem 0;
      }
      a.open-filter-group {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }

    .ProductGroup {
      & + .ProductGroup .subgroup-title {
        padding-top: 2rem;
      }
      .ProductGroup .subgroup-title {
        padding-top: 0;
      }
      .product-group-toggle-row:first-of-type {
        margin-top: 2.5rem;
      }

      & + .ProductGroup .product-group-toggle-row {
        margin-top: 3.5rem;
      }

      .ProductGroup .row.product-group-toggle-row {
        margin-top: 0;
      }
    }

    .product-group-description {
      line-height: 1.2rem;
      font-size: 12px;
    }

    .Product.product-item {
      max-width: 100%;
      &:not(.selected):not(.highlight) {
        box-shadow: none;
      }
      .product-title {
        padding: 0 1rem 0 0;
        font-size: 15px;
      }
      &:not(.disabled):not(.selected) .product-title {
        color: $new-UI-primary-text-dark;
      }
      &:not(.disabled):not(.selected) .product-description {
        color: $new-UI-light-blue-text;
      }
      .product-description {
        font-size: 12px;
        margin-bottom: 0;
      }

    }
    .card {
      .card-body {
        padding: 0.75rem 1rem 0;
      }
      .card-footer {
        //padding: 0 1.25rem 0.25rem;
        .QuantityPicker {
          input[type='text'] {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
      }
    }
    .container.summary-footer-container {
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
      background: #fff;
      border-top: 1px solid #eee;
      padding: 0.75rem 0.75rem 2.5rem;
      .view-summary-btn {
        width: 100%;
        display: flex;
        border-radius: 4px;
        font-size: 14px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        .summary-btn-text {
          font-weight: 700;
          letter-spacing: 0.5px;
          font-size: 13px;
        }
      }
    }
  }
  .card-footer {
    align-items: flex-end;
    display: flex;
    //padding: 0 1.25rem 1rem;
  }
  .modal-dialog {
    max-width: 95vw;
    //max-height: 90vh;
    min-height: calc(100% - (3.5rem * 2));
  }
  .modal-content {
    padding: 1rem !important;
    .modal-body {
      padding: 0;
    }
  }
  .close-modal {
    right: 1.25rem;
    top: 0.75rem;
    font-size: 1.5rem;
  }
  .filter-popup-modal {
    .Product.product-item {
      margin-bottom: -0.25rem;
      max-width: 100%;
    }
    .ProductGroup {
      &:first-of-type {
        margin-top: 1rem;
      }
      .group-title {
        margin-bottom: 0;
      }
    }
    .subtotal-row {
      padding: 0 0.75rem 0.5rem;
    }
    .modal-btn-group {
      padding: 0 0.75rem 0.75rem;
    }
  }
  .modal.summary-modal {
    font-family: 'Open Sans', sans-serif;
    &.fade .modal-dialog {
      -webkit-transform: translate(0, 95%);
      -moz-transform: translate(0, 95%);
      -ms-transform: translate(0, 95%);
      -o-transform: translate(0, 95%);
      transform: translate(0, 95%);
    }
    &.show .modal-dialog {
      margin: 0;
      max-width: 102vw;
      width: calc(100vw + 4px);
      margin-left: -2px;
      height: 100vh;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
      .modal-content {
        padding: 0;
        height: 100vh;
        .close-modal {
          font-size: 26px;
          top: 0.8rem;
          color: $new-UI-mid-grey;
        }
        .modal-body {
          padding: 0;
          .summary-modal-title {
            margin: 0;
            padding: 1rem;
            color: $new-UI-light-blue-text;
            font-size: 26px;
            font-weight: 700;
            border-bottom: 1px solid $new-UI-light-grey;
          }
          .Sidebar {
            box-shadow: none;
            margin-top: 0;
            .final-summary-btn-container {
              padding: 0.25rem 0.25rem 0.5rem;
            }
            .sidebar-content {
              max-height: calc(100vh - 16.25rem);
            }
            .sidebar-footer {
              position: fixed;
              bottom: 2rem;
              width: 100vw;
              .grand-total {
                padding: 0.75rem 1rem;
              }
            }
          }
          .save-quote-btn-container {
            width: 100%;
            text-align: center;
            background: #fff;
            border-top: 1px solid #eee;
            padding: 0.75rem 0.75rem 2.5rem;
            min-height: 7.3rem;
            .save-quote-btn {
              width: 100%;
              display: flex;
              border-radius: 4px;
              font-size: 14px;
              padding-top: 0.25rem;
              padding-bottom: 0.25rem;
              .save-quote-btn-text {
                font-weight: 700;
                letter-spacing: 0.5px;
                font-size: 18px;
                line-height: 2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .modal-dialog:not(.modal-lg) {
    max-width: 600px;
  }
}

.header-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;

  a {
    color: #616e7c;
  }
}

.modal-content {
  padding: 1.25rem;
  border-radius: 0.6rem;
}

.close-modal {
  float: right;
  position: absolute;
  right: 1.3rem;
  top: 1.2rem;
  cursor: pointer;
  z-index: 1000;
}
.blockCard {
  display:block;
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  // border-radius: 10px;
  padding: 10px;
  border: 1px solid #eeeeee;
  width: 100%;
  padding:10px;
  margin: 0 10px 10px 0;
  min-height: 40px;
  position: relative;
  color: #4b5a6b;
  cursor:pointer;

  .footer {
    width: 100%;
  }

  &.selected {
    // background: #2d384a !important;
    color: #2d384a;
    border-left: 6px solid #be3e82;
    border-bottom: none;
    border-top:none;
    border-right:none;
  }

  .selected-badge {
    color: #be3e82;
  }
  .compulsory-badge {
    color: #a6b9c7;
  }

  .corner-badge {
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }


  .block-title {

    text-align: left;
    font-size: 0.9rem;


  }
  .badges-container {
    position:absolute;
    width:20px;
    height:20px;
    right:10px;
    top:10px;

  }
  .block-description {
    min-height: 10px;
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 300;
  }
  .card-footer {
    background: #ffffff !important;
    border: none;
  }
}

.product-group,
.Page {

}
.product-group-description {
  margin-bottom: 10px;
}
.product-group-title {
  font-size: 1.2rem;
  margin: 10px 0 10px 0;
  font-weight: 400;
}
.sidebar-small-print {
  font-size: 0.9rem;
  float: right;
  font-style: italic;
}

.sidebar-menu-item {
  .children {
    width: 100%;
    font-size: 0.9rem;
    color: #4b5a6b;
  }
  h3 {
    font-size:14px;

    margin:0;
  }
}
.sidebar-item {
  font-size: 0.9rem;
  width: 100%;
  padding:5px 0 5px 0;

}
.sidebar-group {
  color:#be3e82;
  margin-top:10px;
  margin-bottom:5px;
  border-bottom: 1px solid #be3e82;
}
#cordero-availability-calendar {
  border: none;
  margin: 25px auto 0 auto;

  .month {
    margin: 40px 0.4rem 0 0.4rem d;
  }
}
.required {
  font-size:0.8rem;
  font-style: italic;
  color:#be3e82;
}
.btn-grey {
  background: #999 !important;
}
.home {
  background:#ffffff;
  padding:5rem;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.list-view {
  font-size:1rem;
  font-style: italic;
}

